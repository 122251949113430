import React from 'react';
import {
  MenuItem,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  IconButton,
  Menu,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

const tabs = [
      { label: 'Person', index: 0 },
      { label: 'Adressen', index: 1 },
      { label: 'Kontakte', index: 4 },
      { label: 'Dokumente', index: 2 },
      { label: 'Genogramm', index: 3 },
];
  
function PersonalNavbar({ isSmallScreen, anchorEl, selectedTab, handleMenuOpen, handleMenuClose, handleSelectChange, handleTabChange }) {
  return (
    <AppBar position="static" color="default" width='100%'
      sx={{
        mt: 2,
        width:'100%'
      }}>
      <Toolbar variant="dense" width='100%' sx={{
        mt: 0,
        width:'100%'
      }}>
        {isSmallScreen ? (
          <>
            <IconButton
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {tabs.map((tab) => (
                <MenuItem
                  key={tab.index}
                  selected={selectedTab === tab.index}
                  onClick={() => handleSelectChange({ target: { value: tab.index } })}
                >
                  {tab.label}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((tab) => (
              <Tab key={tab.index} label={tab.label} />
            ))}
          </Tabs>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default PersonalNavbar;