import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function DashboardCard({label, image, navigateTo}) {
      return <div className="card card-hover" onClick={navigateTo}>
                  <img src={image} className="card-img-top" alt="Bild 1"/>
                  <div className="card-body text-center">
                        <h3 className="card-title">{label}</h3>
                  </div>
            </div>;
}

export default DashboardCard;