import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const ChangeLog = () => {
      const [markdown, setMarkdown] = useState('');
      const filePath = "/changelog.md";

      useEffect(() => {
            fetch(filePath)
                  .then(response => response.text())
                  .then(text => setMarkdown(text));
      }, [filePath]);
  
      return (
            <div className="markdown-body">
                  <ReactMarkdown>{markdown}</ReactMarkdown>
            </div>
      );
}
  export default ChangeLog;