import React, { Component } from 'react';

class DateTime extends Component {
      state = {  } 
      render() { 
            return <div className="input-group">
            <div className="form-floating">
                  <input value={this.props.value} type="date" class="form-control" id="floatingInputGroup1" placeholder="Username"/>
                  <label for="floatingInputGroup1">{this.props.label}</label>
            </div>
      </div>;
      }
}
 
export default DateTime;