import { IAddress, IAddressClassification, IAvatar, IPerson, IPersonMin, IPersonNames, IResidences } from "./types";

export class Person {

      id: number = -1;
      names: PersonNames[] = [];
      title: string = "";
      date_of_birth: string = "";
      date_of_death: string | null = null;
      gender: string = "";
      status: string = "";

      public static default(): Person {
            return new Person();
      }

      static from(data: any): Person {
            let p = new Person();
            p.id = data.id.id;
            p.names = data.names.map((name: any) => PersonNames.from(name));
            p.title = data.title;
            p.date_of_birth = data.date_of_birth;
            if (data.date_of_death === null || data.date_of_death === undefined) {
                  p.date_of_death = null;
            }
            else {
                  p.date_of_death = data.date_of_death;
            }
            p.gender = data.gender;
            p.status = data.status;
      
            return p;
      }

      to_object = (): IPerson => {
            return {
                  id: this.id,
                  names: this.names.map(item => item.to_object()),
                  title: this.title,
                  date_of_birth: this.date_of_birth,
                  date_of_death: this.date_of_death,
                  gender: this.gender,
                  status: this.status,
            };
      }
}

export class PersonNames {
      id: number = -1;
      label: string = "";
      name: string = "";

      static default() : PersonNames {
            return new PersonNames();
      }

      static from(data: any) {
            let p = new PersonNames();
            p.id = data.id.id;
            p.label = data.label;
            p.name = data.name;
            return p;
      }

      to_object = (): IPersonNames => {
            return {
                  id: this.id,
                  label: this.label,
                  name: this.name
            }
      }
}

export class PersonMin {

      id: number = -1;
      names: string = "";

      static default(): PersonMin {
            return new PersonMin();
      }

      static from(item: any) {
            let r = new PersonMin();
            r.id = item.id.id;
            r.names = item.names;
            return r;
      }

      to_object = (): IPersonMin => {
            return {
                  id: this.id,
                  names: this.names
            };
      }
}

export class Address {
      id: number = -1;
      person_id: number = -1;
      street: string = "";
      city: string = "";
      house_number: string = "";
      postal_code: string = "";
      country: string = "";
      state_or_province: string = "";
      address_classification: IAddressClassification = AddressClassification.default().to_object();
      residence: IResidences = Residences.default().to_object();
      
      static default() : Address {
            return new Address();
      }

      static from(addr: any): Address {
            
            let dest = new Address();
            dest.id = addr.id.id;
            dest.person_id = addr.person_id;
            dest.street = addr.street;
            dest.city = addr.city;
            dest.house_number = addr.house_number;
            dest.postal_code = addr.postal_code;
            dest.country = addr.country;
            dest.state_or_province = addr.state_or_province;
            dest.address_classification = addr.address_classification;
            dest.residence = addr.residence;
      
            return dest;
      }

      to_object = () : IAddress => {
            return {
                  id: this.id,
                  person_id: this.person_id,
                  street: this.street,
                  city: this.city,
                  house_number: this.house_number,
                  postal_code: this.postal_code,
                  country: this.country,
                  state_or_province: this.state_or_province,
                  address_classification: this.address_classification,
                  residence: this.residence
            };
      }
}

export class AddressClassification {
      id: number = -1;
      label: string = "";
      
      public static default(): AddressClassification {
            return new AddressClassification();
      }

      to_object = (): IAddressClassification => {
            return {
                  id: this.id,
                  label: this.label
            };
      }
}

export class Residences {
      id: number = -1;
      move_in_date: string = "";
      move_out_date: string = "";

      public static default(): Residences {
            return new Residences();
      }

      public from(data: any): Residences {
            let p = new Residences();
            p.id = data.id.id;
            p.move_in_date = data.move_in_date;
            p.move_out_date = data.move_out_date;
            return p;
      }

      to_object = (): IResidences => {
            return {
                  id: this.id,
                  move_in_date: this.move_in_date,
                  move_out_date: this.move_out_date
            }
      }
}

export class Avatar {
      image: string = "";

      public static default(): Avatar {
            return new Avatar();
      }

      public static from(data: any): Avatar {
            let avatar = new Avatar();
            avatar.image = data;
            return avatar;
      }

      to_object = (): IAvatar => {
            return {
                  image: this.image
            };
      }
}