import React, { createContext, useState, useContext } from 'react';

const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

/**
 * The DialogProvider component manages the display of dialog components in a React application.
 * @returns The `DialogProvider` component is being returned. It is a context provider component that
 * provides the `dialog`, `showDialog`, and `hideDialog` functions to its children via the
 * `DialogContext.Provider`.
 */
export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState(null);

  const showDialog = (dialogComponent) => {
    setDialog(dialogComponent);
  };

  const hideDialog = () => {
    setDialog(null);
  };

  return (
    <DialogContext.Provider value={{ dialog, showDialog, hideDialog }}>
      {children}
    </DialogContext.Provider>
  );
};
