// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './auth_context';
//import Login from './login';
import AppContent2 from './mui_app_content';
import { DialogProvider } from './dialog_context';
import DialogPortal from './dialog_portal';
import LoginForm from './mui_login_page';

import store from '../store'
import { Provider } from 'react-redux'

const PrivateRoute = ({ children }) => {

      const { isAuthenticated, loading } = useAuth();

      console.log("--> PrivateRoute: isAuthenticated, loading", isAuthenticated, loading);

      if (loading) {
            return <div>Loading...</div>;
      }
    
      return isAuthenticated ? children : <Navigate to="/login" replace />;
};

const App = () => {

    // const store = createStoreHook(personReducer);

    return (
        <React.StrictMode>
            <Provider store={store}>
                <DialogProvider>
                    <DialogPortal />
                    <AuthProvider>
                        <Router>
                            <Routes>
                                <Route path="/login" element={<LoginForm />} />
                                <Route path="*" element={<PrivateRoute><AppContent2 /></PrivateRoute>} />
                                {/* <Route path="*" element={<Navigate to="/content" replace />} /> */}
                            </Routes>
                        </Router>
                    </AuthProvider>
                </DialogProvider>
            </Provider>
        </React.StrictMode>
    );
};

export default App;
