import React, { useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box,
   useTheme,
      useMediaQuery,
   Modal
} from '@mui/material';
import ApplicationHeader from './mui_app_header';
import ApplicationNavbar from './mui_app_navbar';
import ApplicationMainContent from './mui_main_content';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const AppContent2 = () => {
      const [mobileOpen, setMobileOpen] = useState(false);
      const [anchorEl, setAnchorEl] = useState(null);
      const [darkMode, setDarkMode] = useState(false);
      const [selectedItem, setSelectedItem] = useState('DASHBOARD');

      const navigate = useNavigate();

      const handleDrawerToggle = () => {
            setMobileOpen(!mobileOpen);
      };

      const handleMenuOpen = (event) => {
            setAnchorEl(event.currentTarget);
      };

      const handleMenuClose = () => {
            setAnchorEl(null);
      };

      const handleMenuItemClick = (item) => {
            setSelectedItem(item);
            navigate(item);
      };

      const theme = useMemo(
            () =>
                  createTheme({
                        palette: {
                              mode: darkMode ? 'dark' : 'light',
                        },
                  }),
            [darkMode]
      );
  
      //const theme = useTheme();
      const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));

      const toggleDarkMode = () => {
            setDarkMode(!darkMode);
      };

      return (
            <ThemeProvider theme={theme}>
                  <Box sx={{ display: 'flex', flex: 1, height: '100vh', width: '100%', overflowY: 'hidden', overflowX: 'hidden' }}>
                        <CssBaseline />
                        <ApplicationHeader
                              handleDrawerToggle={handleDrawerToggle}
                              handleMenuClose={handleMenuClose}
                              handleMenuOpen={handleMenuOpen}
                              toggleDarkMode={toggleDarkMode}
                              darkMode={darkMode}
                              anchorEl={anchorEl} />


                        <ApplicationNavbar mobileOpen={mobileOpen}
                              handleDrawerToggle={handleDrawerToggle}
                              handleMenuItemClick={handleMenuItemClick}
                              selectedItem={selectedItem}
                              drawerWidth={drawerWidth} />
                    
                        <Box sx={{
                              display: 'flex',
                              flex: 1,
                              overflowY: 'hidden',
                              width: '100%',
                        }}>
                              <ApplicationMainContent isSmallScreen={isSmallScreen} drawerWidth={drawerWidth} />
                        </Box>
                  </Box>
            </ThemeProvider>
      );
};

export default AppContent2;
