import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetch_person_by_id_async } from './personSlice'

import {
  Box,
  Paper
} from '@mui/material';

import PersonalFormula from './person_form';
import PersonalImageCard from './avatar_card';
import PersonalNavbar from './navbar';
import PersonalControl from './control';
import AddressFormula from './address_form';
import PersonalDocument from './person_doc';
import PersonalGenogram from './person_genogram';
import { useParams } from 'react-router-dom';
import ModalPersonSearch from './modal_person_serach' 

const PersonalFormPage = ({ isSmallScreen }) => {
  const dispatch = useDispatch()
  let person = useSelector(state => state.person.person);

  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [gender, setGender] = useState('');
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const handleReset = () => {
    setGender('');
    setTitle('');
    setFirstName('');
    setLastName('');
    setDateOfBirth('');
    setAddress('');
    setPhone('');
    setEmail('');
  };

  useEffect(() => {
    if (id !== undefined && person !== undefined) {
      const id_p = parseInt(id, 10);
      if (!isNaN(id_p) && id_p !== person.id) {
        debugger;
        console.debug("PersonalFormula/useEffect The ID has changed id: ", id)
        dispatch(fetch_person_by_id_async(id));
      }
    }
  }
);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectChange = (event) => {
    setSelectedTab(event.target.value);
    handleMenuClose();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleSave = () => {
    // Save logic here
    console.log('Data saved');
  };

  let content = {};
  switch (selectedTab) {
    case 1: content = <AddressFormula isSmallScreen={isSmallScreen}
      gender={gender} title={title} firstName={firstName}
      lastName={lastName} dateOfBirth={dateOfBirth} address={address}
      phone={phone} email={email}
      setGender={setGender} setTitle={setTitle} setFirstName={setFirstName}
      setLastName={setLastName} setDateOfBirth={setDateOfBirth}
      setAddress={setAddress} setPhone={setPhone} setEmail={setEmail} />;
      break;
    case 2: content = <PersonalDocument isSmallScreen={isSmallScreen} />; break;

    case 3: content = <PersonalGenogram />; break;
    default: content = <PersonalFormula isSmallScreen={isSmallScreen} person={person} />;
      break;
  }
  
  console.log("IsSmallerScreen: ", isSmallScreen);
  return (
    <div id="test3">
      <ModalPersonSearch />
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%', maxHeight:'100%' }}>
      
        <PersonalNavbar isSmallScreen={isSmallScreen}
          anchorEl={anchorEl} selectedTab={selectedTab} handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose} handleSelectChange={handleSelectChange} handleTabChange={handleTabChange} />
        <Box
          sx={{
            flex: 1, m: 0, mt: 2,
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            width: '100%'
            // gap: 2,
          }}
        >
          <Paper
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              // height: isSmallScreen ? 'auto' : '80%', // Hier setzen wir die Höhe auf automatisch
              m: 1,
              p: 2,
              gap: 2,
              width: isSmallScreen ? 'auto' : '60%'
            }}
            elevation={3}
          >

            {content}
          </Paper>

          <Paper
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              m: 1,
              p: 2,
              gap: 2,
              alignItems: 'center',
              width: isSmallScreen ? 'auto' : '40%'
            }}
            elevation={3}
          >
            <PersonalImageCard isSmallScreen={isSmallScreen} handleReset={handleReset} handleSave={handleSave} />
          </Paper>
        </Box>

        <PersonalControl />
      </Box>
    </div>);
};

export default PersonalFormPage;
