import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from "../../hooks/api"
import { dispatch } from 'd3';

const initialState = {
  status: "idle",
  // api: {},
  query: '',
  // list: {},
  // content: {},
  dialog_open : false,
}

// export const search_async = createAsyncThunk('serach/text', async (path, text) => {
//   console.log("Reducer: search_async");
//   console.log(path);
//   console.log(text);
//   //const response = await api.search(path, text);
//   const response = await api.find_persons(text);

//   console.log("Reducer: search_async");
//   console.log(response);
//   return response.data
// })

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    open_dialog(state, action) {
      if (state.query !== '')
      {
        state.dialog_open = true;
      }
    },
    close_dialog(state, action) {
      state.query = '';
      state.dialog_open = false;
    },
    search_change(state, action) {
      console.log("search_change");
      console.log(action.payload);
      state.query = action.payload
      //search_async(state.api, action.payload);
    },
    clear_search_text(state, action) {
      state.query = '';
    },
    update_list(state, action) {
      state.list = action.payload
    },
    set_api(state, action) {
      state.api = action.payload
    },
    update_content(state, action) {
      debugger;
      state.dialog_open = true;
      state.content = action.payload
    },
  }
//  }
})
    
export const { search_change, update_list, set_api,update_content,clear_search_text, open_dialog, close_dialog } = searchSlice.actions
//export const select_person = state => state.value
export default searchSlice.reducer