import React, { Component } from 'react';
import InputBox from './input_box';
import 'react-datepicker/dist/react-datepicker.css';
import DateTime from './datetime';
import Gender from './gender';
import Title from './title';

import "../index.css"

class AddressInfo extends Component {
      state = {  } 
      render() { 
            return <div>
                  <form className="g-8">
                        
                  {this.props.addresses.map((item) => (
                        <div >
                              <div className='row row-spacing'>
                                    <div className="col-9">
                                          <InputBox label="Strasse" value={item.street}/>
                                    </div>
                                    <div className="col-3">
                                          <InputBox label="Hausnummer" value={item.house_number}/>
                                    </div>
                              </div>

                              <div className='row row-spacing'>
                                    <div className='col-3'>
                                          <InputBox label="PLZ" value={item.postal_code}/>
                                    </div>                              
                                    <div className="col-9">
                                          <InputBox label="Ort" value={item.city}/>
                                    </div>
                              </div>

                              <hr />
                        </div>))
                  }                        
      </form>
      </div>;
      }
}
 
export default AddressInfo;