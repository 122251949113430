import React, {useEffect, useRef }from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector } from 'react-redux';
import { PersonMin } from "../../model/Person";
import { search_change,close_dialog } from '../search/searchSlice';
import { find_person_by_name_async } from './personSlice';

import PersonListItem from "./person_list_item";
import {
      TextField,
      Box,
      List,
      Button,
      Modal
} from '@mui/material';
import ReactDOM from 'react-dom';
import { AppDispatch } from '../../store';

// interface PersonSearchProp {
//       query: string;
//       persons: Array<PersonMin>;
//       handleSelectPerson: (id: number) => void;
//       handleSearchTextChanged:(text: string) => void;
// }
    
const ModalPersonSearch: React.FC = () => {
      const textFieldRef = useRef<HTMLInputElement>(null);
      const query: string = useSelector((state: any) => state.search.query);
      let persons: PersonMin[] = useSelector((state: any) => state.person.search_persons);

      if (persons === null)
      {
            persons = new Array<PersonMin>();
      }
      const navigate = useNavigate();
      const dispatch : AppDispatch = useDispatch()

      const handleSelectPerson = (id: number) => {
            navigate(`/person/${id}`);
            dispatch(close_dialog(''));
      };
        
      const handlerSearchTextChanged = (e: string) => {
            dispatch(search_change(e));
            dispatch(find_person_by_name_async(e));
      }

      const handleClose = () => {
            dispatch(close_dialog(''));
      }

      useEffect(() => {
            setTimeout(() => {
                  if (textFieldRef.current) {
                        textFieldRef.current.focus();
                  }
            }, 0);
      });

      return ReactDOM.createPortal(
            <Modal
                  open={query.length > 0}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
            >
                  <Box sx={{ width: '100%', maxWidth:600, height: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 1, overflow: 'hidden' }}>
                        <Box sx={{ width: '100%', overflowY: 'auto', padding: 2 }}>
                              <h2 id="modal-title">Personen suche</h2>
                              <TextField
                                    inputRef={textFieldRef}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    label="Suche nach"
                                    value={query}
                                    onChange={(e) => {
                                          handlerSearchTextChanged(e.target.value);
                                    }}
                              />
                              <Box sx={{
                                    height: 300,
                                    overflowY: 'auto',
                                    mt: 2,
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                              }}>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                          {
                                                persons.map((person) => (
                                                      <PersonListItem
                                                            person={person}
                                                            key={person.id}
                                                            handleSelectPerson={handleSelectPerson}
                                                      />
                                                ))
                                          }
                                    </List>
                              </Box>
                        </Box>
                        <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                              Close
                        </Button>
                  </Box>
            </Modal>
            , document.body);
};

export default ModalPersonSearch;
