import React, {useEffect, useState} from 'react';
import Search from '@mui/icons-material/Search';
import {
  TextField,
  Card,
  CardContent,
  List,
  ListItem,
  Box,
  Paper,
  Container,
  Collapse,
  Button
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AddressFormItem } from './address_from_item';
import { address_update } from './personSlice';    
import ModalAddressSearch from './modal_address_serach';

function AddressFormula({ isSmallScreen }) {
  const dispatch = useDispatch()
  let addresses = useSelector(state => state.person.addresses);
    
  if (addresses === null || addresses === undefined) {
    addresses = [];
  }
  const handlerInputChange = (e, id) => {
    console.log("handleInputChange");
    console.log(e);
    const { name, value } = e.target;
    dispatch(address_update({ [name]: value, id: id }));
  };

  const handleOpen = (id) => {
    setOpen(id);
  }
  const [open, setOpen] = useState(-1);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    if (addresses.length > 0 && open < 0) {
      setOpen(addresses[0].id);
    }
  }, []);

  const handleAddAddress = () => {
    setSearch(true)
  }

  return (
    // <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%', maxWidth:'100%', gap: 2, pb: 3, pt: 2 }}>
    <>
    <ModalAddressSearch open={search} handleClose={()=>{setSearch(false)}} />

      <Button onClick={handleAddAddress}>
        <Search />
      </Button>
      <List sx={{maxWidth:'100%'}}>
        {
          addresses && addresses.map((item) => (
            <ListItem key={item.id}>
              <AddressFormItem address={item} open_id={open} handleToggleOpen={handleOpen} handleInputChange={(e) => handlerInputChange(e, item.id)} />
            </ListItem>
          ))
        }
      </List>
      </>
    //{/* </Container> */}
  );
}

export default AddressFormula;