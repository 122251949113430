import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardCard from './dashboard_card';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
      const navigate = useNavigate();
      
      const cards = [
            {
                  label: "Personenverwaltung",
                  image: "person.webp",
                  navigateTo: () => navigate('/persons/*'),
            },
            {
                  label: "Adressverwaltung",
                  image: "address.webp",
                  navigateTo: () => navigate('/persons/*'),
            },
            {
                  label: "Dokumentenverwaltung",
                  image: "doc.webp",
                  navigateTo: () => navigate('/persons/*'),
            }
      ]
      return <div className="container mt-5">
            <div className="row">
                  {cards.map(card => (
                        <div key={card} className="col-sm-12 col-md-6 col-lg-4 mb-4">
                              <DashboardCard
                                    label={card.label}
                                    image={card.image}
                              />
                        </div>
                  ))}
            </div>

            </div>
}

export default Dashboard;