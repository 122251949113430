import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
//import * as go from 'gojs';

const familyData = {
  name: "Großeltern",
  type: "circle", // Kreis für die Großeltern
  children: [
    {
      name: "Elternteil",
      type: "rect", // Rechteck für die Eltern
      children: [
        {
          name: "Kind 1",
          type: "circle" // Kreis für Kind 1
        },
        {
          name: "Kind 2",
          type: "circle" // Kreis für Kind 2
        }
      ]
    },
    {
      name: "Onkel/Tante",
      type: "rect", // Rechteck für Onkel/Tante
      children: [
        {
          name: "Cousin/Cousine",
          type: "circle" // Kreis für Cousin/Cousine
        }
      ]
    }
  ]
};

const GenogramDemo = () => {
  const ref = useRef();
  const data = familyData;
  useEffect(() => {
    const svg = d3.select(ref.current)
      .attr('width', '100%')
      .attr('height', 500)
      .style('border', '1px solid black');

    const root = d3.hierarchy(data);
    const treeLayout = d3.tree().size([400, 300]);
    const treeData = treeLayout(root);

    const links = svg.selectAll('.link')
      .data(treeData.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('d', d3.linkHorizontal()
        .x(d => d.y)
        .y(d => d.x))
      .style('fill', 'none')
      .style('stroke', '#555')
      .style('stroke-width', '2px');

    const nodes = svg.selectAll('.node')
      .data(treeData.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.y},${d.x})`);

    nodes.each(function(d) {
      const node = d3.select(this);
      if (d.data.type === 'circle') {
        node.append('circle')
          .attr('r', 10);
          //.style('fill', '#999');
      } else if (d.data.type === 'rect') {
        node.append('rect')
          .attr('width', 20)
          .attr('height', 14)
          .attr('x', -10)
          .attr('y', -7);
          //.style('fill', '#bbb');
      }
    });

    nodes.append('text')
      .attr('dy', 3)
      .attr('x', d => d.children ? -13 : 13)
      .style('text-anchor', d => d.children ? 'end' : 'start')
      .text(d => d.data.name);
  }, [data]);

  return <svg ref={ref}></svg>;
};

export default GenogramDemo;

















//const $ = go.GraphObject.make; // Eine schnelle Referenz für das GoJS make-Utility

// const Genogram = () => {
//   const diagramRef = useRef(null);

//   useEffect(() => {
//     if (diagramRef.current) {
//       // Initialisiert ein neues Diagramm und bindet es an das div-Element
//       const myDiagram = $(go.Diagram, diagramRef.current, {
//         'undoManager.isEnabled': true, // Aktiviert Undo & Redo
//         layout: $(go.TreeLayout), // Verwendet ein TreeLayout für das Diagramm
//       });

//       // Definiert das Aussehen und Verhalten der Knoten
//       myDiagram.nodeTemplate =
//         $(go.Node, 'Horizontal',
//           { background: '#44CCFF' },
//           $(go.TextBlock, { margin: 12, stroke: 'white', font: 'bold 16px sans-serif' },
//             new go.Binding('text', 'name'))
//         );

//       // Erstellt das Modelldiagramm aus Daten
//       myDiagram.model = new go.TreeModel([
//         { key: '1', name: 'Parent' },
//         { key: '2', parent: '1', name: 'Child 1' },
//         { key: '3', parent: '1', name: 'Child 2' }
//       ]);
//     }
//   }, []);

//   return <div ref={diagramRef} style={{ width: '100%', height: '400px' }} />;
// };

// export default Genogram;
