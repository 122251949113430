import { useState } from "react";
import { IAddress } from "../../model/types";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import {
      TextField,
      Card,
      CardContent,
      List,
      ListItem,
      Box,
      Paper,
      Container,
      Collapse,
      ListItemButton,
      ListItemText,
      MenuItem
} from '@mui/material';
import { add } from "date-fns";

const Classifications = [
      {id: 1, label: '---'},
      {id: 2, label: 'Kundenadresse'},
      {id: 3, label: 'Lieferantenadresse'},
      {id: 4, label: 'Behördenadresse'},
];

interface AddressFormItemProps {
      address: IAddress;
      open_id: number;
      handleToggleOpen: (id: number) => void;
      handleInputChange:  React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>| undefined;
}

export const AddressFormItem: React.FC<AddressFormItemProps> = ({ address, open_id, handleToggleOpen, handleInputChange }) => {
      const title = address.street + " " + address.house_number + " " + address.postal_code + " " + address.city;

      const open = open_id === address.id;
      return (
            <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
                  <ListItemButton onClick={() => handleToggleOpen(open ? -1 : address.id)}>
                        <ListItemText primary={title} />
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </ListItemButton>
                  <Collapse in={open}>
                        <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%', gap: 2, pb: 3, pt: 2 }}>
                              <TextField
                                    select
                                    label="Classification"
                                    name="Classification"
                                    value={address?.address_classification?.label ?? ''}
                                    // onChange={handlerInputChange}
                                    variant="outlined"
                              >
                                    {Classifications.map((option) => (
                                          <MenuItem key={option.id} value={option.label}>
                                                {option.label}
                                          </MenuItem>
                                    ))}
                              </TextField>
                              <TextField
                                    label="Straße"
                                    name="street"
                                    value={address.street}
                                    onChange={handleInputChange}
                                    variant="outlined"
                              />
                              <TextField
                                    label="Hausnummer"
                                    name="house_number"
                                    value={address.house_number}
                                    onChange={handleInputChange}
                                    variant="outlined"
                              />
                              <TextField
                                    label="PLZ"
                                    name="postal_code"
                                    value={address.postal_code}
                                    onChange={handleInputChange}
                                    variant="outlined"
                              />
                              <TextField
                                    label="Ort"
                                    name="city"
                                    value={address.city}
                                    onChange={handleInputChange}
                                    variant="outlined"
                              />
                              <TextField
                                    label="country"
                                    name="country"
                                    value={address.country}
                                    onChange={handleInputChange}
                                    variant="outlined"
                              />
                              <TextField
                                    label="state_or_province"
                                    name="state_or_province"
                                    value={address.state_or_province}
                                    onChange={handleInputChange}
                                    variant="outlined"
                              />
                        </Container>
                  </Collapse>
            </Container>
      );
}