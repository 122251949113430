import React from 'react';
import {
  Box,
      Container,
      Modal,
      Typography,
      Button
} from '@mui/material';

import { Routes, Route } from 'react-router-dom';
import AddressInfo from './address_info';
import Dashboard from './dashboard';
import ChangeLog from './change_log' 

import PersonalFormPage from '../features/person/page'

/* This code defines a React functional component named `ApplicationMainContent` that takes two props:
`isSmallScreen` and `drawerWidth`. Inside the component, it returns JSX code that represents the
main content area of an application layout. */
function ApplicationMainContent({ isSmallScreen, drawerWidth }) {
      return (
            <Box
                  sx={{ flexGrow: 1, p: 1, width: '100%', display: 'flex', flexDirection: 'column', mt: 8, overflow: 'hidden' }}
            >
                  <Container id="test123" maxWidth='100%' width='100%' sx={{ flex: 1, display: 'flex', width: '100%', flexDirection: 'column', overflowY: 'auto', overflowX: 'hidden' }}>
                        <Routes>
                              <Route path="dashboard" element={<Dashboard />} />
                              <Route path="changelog" element={<ChangeLog />} />
                              <Route path="/processes" element={<h1>{"Vorgänge"}</h1>} />
                              <Route path="/person/*" element={<PersonalFormPage isSmallScreen={isSmallScreen} />} />
                              <Route path="/person/:id" element={<PersonalFormPage isSmallScreen={isSmallScreen} />} />
                              <Route path="/persons/address1" element={<AddressInfo addresses={[]} />} />
                        </Routes>
                  </Container>
            </Box>
      );
}

export default ApplicationMainContent;