import React from 'react';
import {
  Box,
  Button,
  IconButton,
} from '@mui/material';
import { Save as SaveIcon, Cancel as CancelIcon, Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { update_person_async,person_new } from './personSlice'

function PersonalControl({ isSmallScreen, handleReset, handleSave }) {
  const person = useSelector(state => state.person.person);
  console.log("Control: " + person);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const onFetchClicked = () => {
  //   // Calls the thunk action creator, and passes the thunk function to dispatch
  // }

  const new_click = (event) => {
    console.log("Click new");
    navigate(`/person/`);
    dispatch(person_new());
  };
  const cancel_click = (event) => { };
  const save_click = (event) => { 
    console.log("Click save");
    dispatch(update_person_async(person));
  };

  return (
    <Box sx={{ p: 2, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        {isSmallScreen ? (
          <>
            <IconButton color="primary" onClick={new_click}>
              <AddIcon />
            </IconButton>
            <IconButton color="secondary" onClick={save_click}>
              <SaveIcon />
            </IconButton>
            <IconButton color="error" onClick={cancel_click}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={new_click}
            >
              Neu
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={save_click}
            >
              Speichern
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={cancel_click}
            >
              Abbruch
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

export default PersonalControl;