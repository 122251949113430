import React from 'react';
import {
  Button,
  Avatar,
  Card,
  CardContent,
  CardActions,
  Typography,
  Paper,
  CssBaseline
} from '@mui/material';
import { PhotoCamera} from '@mui/icons-material';
import { api } from "../../hooks/api"
import { useDispatch, useSelector } from 'react-redux';
import { fetch_person_by_id_async } from './personSlice'

function PersonalImageCard({ isSmallScreen }) {

  const dispatch = useDispatch()
  let avatar = useSelector(state => state.person.avatar);
  let person = useSelector(state => state.person.person);

  console.log("Avatar IMG: " , avatar?.image);

  const bufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const base64String = bufferToBase64(arrayBuffer);
        if (person.id) {
          await api.avatar_to_person(person.id, base64String);
          dispatch(fetch_person_by_id_async(person.id));
        }
        // Hier kannst du den Inhalt der Datei weiter verarbeiten
      };
      reader.readAsArrayBuffer(file)
    }
  };

  return (
    <>
      <Avatar
        alt="Profilbild"
        src={`data:image/jpeg;base64,${avatar?.image || ''}`}
        sx={{ width: 200, height: 200 }}
      />

      <CssBaseline />

      <Button
        variant="contained"
        component="label"
        startIcon={<PhotoCamera />}
      >
        Bild hochladen
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
    </>
  );
}

export default PersonalImageCard;