// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
//import axios from 'axios';
//import { api } from '../hooks/api';
import { api } from '../hooks/api';
import { useDialog } from './dialog_context';
import LoginFaildDialog from './login_faild_dialog';
import {useDispatch, useSelector } from 'react-redux';
import {login} from '../features/authentication/authSlice'
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
      const dispatch = useDispatch()
      const is_authenticated = useSelector(state => state.auth.is_authenticated);

      const [isAuthenticated, setAuthenticated] = useState(false);
      const [loading, setLoading] = useState(true);
      const { showDialog } = useDialog();

      useEffect(() => {
            checkToken();
      }, [is_authenticated]);

      const checkToken = async () => {
            let is_auth = false;
            try {
                  is_auth = await api.verify_user_authentication();
                  dispatch(login(''))
                  setAuthenticated(is_auth);
            } catch (error) {
                  setAuthenticated(false);
                  console.error('Token verification failed:', error);
            }
            setLoading(false);
            return is_auth
      };

      const handle_login = async () => {
            if (await checkToken() === false) {
                  showDialog(<LoginFaildDialog show="true" />);
            }
      };

      const logout = () => {
            setAuthenticated(false);
      };

      return (
            <AuthContext.Provider value={{ isAuthenticated, loading, login: handle_login, logout }}>
                  {children}
            </AuthContext.Provider>
      );
};
