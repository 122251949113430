import React from 'react';
import {
  TextField,
  Card,
  CardContent,
} from '@mui/material';

    
function PersonalDocument({ isSmallScreen}) {
        
      return (<Card
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              height: isSmallScreen ? '90%' :'auto', // Hier setzen wir die Höhe auf automatisch
              overflowY: isSmallScreen ? 'visible' : 'auto',
            }}
          >
            <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Dokumente"
                variant="outlined"
              />
            </CardContent>
          </Card>
  );
}

export default PersonalDocument;