import { configureStore } from '@reduxjs/toolkit'
import personReducer from './features/person/personSlice'
import searchSlice from './features/search/searchSlice'
import authSlice from './features/authentication/authSlice';

const store = configureStore({
  reducer: {
    person: personReducer,
    search: searchSlice,
    auth: authSlice,
  }
})

// Typ für den `dispatch`
export type AppDispatch = typeof store.dispatch;

export default store