import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from "../../hooks/api"
import { Person, Avatar } from '../../model/Person';
import { loading_state, idle_state, failed_state } from '../../utils/slice_state_base';
import { IAddress, IAvatar, IPerson, IPersonMin } from '../../model/types';

interface PersonData {
  person: IPerson|null,
  addresses: IAddress[]|null,
  avatar: IAvatar|null,
}
interface PersonSliceState  {

  person: IPerson;
  addresses: IAddress[];
  avatar: IAvatar;
  search_persons: IPersonMin[];
  status: string;
}

const default_state = (): PersonSliceState => {
  return {
    person: Person.default().to_object(),
    addresses: new Array<IAddress>(),
    avatar: Avatar.default().to_object(),
    search_persons: new Array<IPersonMin>(),
    status: idle_state(),
  };
}

const initialState: PersonSliceState = default_state();

export const find_person_by_name_async = createAsyncThunk<IPersonMin[]|null, string>('person/person_find', async (name: string)  => {
  console.debug("Person-Reducer: find_person_by_name_async name: ", name)
  const response = await api.find_persons({name: name});
  return response.data;
});

export const fetch_person_by_id_async = createAsyncThunk<PersonData, number>('person/person_get', async (person_id: number) => {
  console.debug("Person-Reducer: fetch_person_by_id_async id: ", person_id)
  debugger;

  const person = api.get_person_by_id(person_id)
  const avatar = api.get_avatar_image_by_person_id(person_id);
  const addresses = api.get_addresses_by_person_id(person_id);

  const data : PersonData= {
    person: (await person).data,
    avatar: (await avatar).data,
    addresses: (await addresses).data
  };

  console.log("Reducer: fetch_person_by_id_async", data);
  return data
})

// export const fetch_avatar_by_person_id_async = createAsyncThunk('person/person_avatar', async (person_id:number) => {
//   console.log("Reducer: fetch_avatar_by_person_id_async")
//   const response = await api.get_avatar_image_by_person_id(person_id)
//   console.log("Reducer: fetch_avatar_by_person_id_async - ", response)
//   return response
// })

export const update_person_async = createAsyncThunk('person/person_update', async (person : Person) => {
  console.log("Reducer: update_person_async");
  const response = await api.update_person(person);
  console.log("Reducer: update_person - ", response)
  return response
})

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    // person_get(state, action) {
    //   state.value = action.payload;
    // },
    person_update(state, action) {
      state.person = {
        ...state.person,
        ...action.payload
      };
      console.log("person_update");
      console.log(state.person);
    },
    person_name_update(state, action) {
      console.log("person_name_update");
      console.log(action);
      state.person = {
        ...state.person,
        names: state.person.names.map((name, i) =>
          i === action.payload.index
            ? { ...name, name: action.payload.value }
            : name
        )
      }
      console.log(state.person);
    },
    address_update(state, action) {
      console.log("address_update ", state.addresses, " payload: " , action.payload);
      const address_index = state.addresses.findIndex(item => item.id === action.payload.id);
      state.addresses[address_index] = {
        ...state.addresses[address_index],
        ...action.payload
      }
      console.log(state.addresses);
    },
    person_new(state) {
      return default_state();
    }

    //CaseReducer<PersonSliceState, PayloadAction<undefined, string, { arg: number; requestId: string; requestStatus: "pending"; }, never>>

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch_person_by_id_async.pending, (state) => {
        state.status = loading_state();
        console.log("Reducer async state: ", state.status);
      })
      .addCase(fetch_person_by_id_async.fulfilled, (state, action) => {
        state.status = idle_state();
        state.person = action.payload.person ?? Person.default().to_object();
        state.addresses = action.payload.addresses ?? [];
        state.avatar = action.payload.avatar ?? Avatar.default().to_object();
        console.log("Reducer async state: ", state.status);
      })
      .addCase(fetch_person_by_id_async.rejected, (state) => {
        state.status = failed_state();
        console.error("Reducer async state: ");
        console.log("Reducer async state: ", state.status);
      })


      .addCase(find_person_by_name_async.pending, (state) => {
        state.status = loading_state();
        console.log("Reducer async state: ", state.status);
      })
      .addCase(find_person_by_name_async.fulfilled, (state, action) => {
        state.status = idle_state();
        const p = action.payload ?? [];
        state.search_persons = p;
        console.log("Reducer async state: ", state.status);
      })
      .addCase(find_person_by_name_async.rejected, (state) => {
        state.status= failed_state();
        console.error("Reducer async state: ");
        console.log("Reducer async state: ", state.status);
      })




      // .addCase(fetch_avatar_by_person_id_async.pending, (state) => {
      //   state.loading();
      //   console.log("Reducer async state: ", state.status);
      // })
      // .addCase(fetch_avatar_by_person_id_async.fulfilled, (state, action) => {
      //   state.idle();
      //   if (action.payload) {
      //     state.avatar = action.payload;
      //   }
      // })
      // .addCase(fetch_avatar_by_person_id_async.rejected, (state) => {
      //   state.failed();
      //   console.log("Reducer async state: ", state.status);
      // })


      .addCase(update_person_async.pending, (state) => {
        state.status = loading_state();
        console.log("Reducer async state: ", state.status);
      })
      .addCase(update_person_async.fulfilled, (state, action) => {
        state.status = idle_state();
        state.person = action.payload.data ?? Person.default().to_object();
        console.log("Reducer async state: ", state.status);
      })
      .addCase(update_person_async.rejected, (state) => {
        state.status = failed_state();
        console.log("Reducer async state: ", state.status);
      })
      // .addCase(find_person_by_name_async.pending, (state) => {
      //   state.loading();
      //   console.log("Reducer async state: ", state.status);
      // })
      // .addCase(find_person_by_name_async.fulfilled, (state, action) => {
      //   state.idle();
      //   state.value = action.payload;
      // })
      // .addCase(find_person_by_name_async.rejected, (state) => {
      //   state.status = 'failed';
      //   console.log("Reducer async state: ", state.status);
      // });
  },
})
    
export const { person_new , person_update, person_name_update, address_update} = personSlice.actions
export default personSlice.reducer