import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from "../../hooks/api"
import { loading_state, idle_state, failed_state } from '../../utils/slice_state_base';

interface AuthenticationState  {

      is_authenticated: boolean;
      status: string;
}

interface LoginProp {
      email: string;
      password: string;
}

const default_state = (): AuthenticationState => {
  return {
        is_authenticated: false,
        status: idle_state(),
  };
}

const initialState: AuthenticationState = default_state();

export const logout_async = createAsyncThunk('authentication/logout', async () => {
      await api.user_logout();
      return false;
});

export const login_async = createAsyncThunk('authentication/login', async (login : LoginProp) => {
      const result = await api.user_login(login.email, login.password);
      return result;
});

const authSlice = createSlice({
      name: 'authentication',
      initialState,
      reducers: {
            login(state, action) {
                  state.status = idle_state();
                  state.is_authenticated = true;
            },
            logout(state, action) {
                  state.status = idle_state();
                  state.is_authenticated = false;
            },
      },
      extraReducers: (builder) => {
            builder
                  .addCase(logout_async.pending, (state) => {
                        state.status = loading_state();
                  })
                  .addCase(logout_async.fulfilled, (state, action) => {
                        state.status = idle_state();
                        state.is_authenticated = false;
                  })
                  .addCase(logout_async.rejected, (state) => {
                        state.status = failed_state();
                  })

            
                  .addCase(login_async.pending, (state) => {
                        state.status = loading_state();
                  })
                  .addCase(login_async.fulfilled, (state, action) => {
                        state.status = idle_state();

                        state.is_authenticated = action.payload;
                  })
                  .addCase(login_async.rejected, (state) => {
                        state.status = failed_state();
                  })
            
      },
})
        
export const { login, logout } = authSlice.actions
export default authSlice.reducer