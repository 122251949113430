import React from 'react';
import Drawer from '@mui/material/Drawer';
import {
  Box,
  MenuItem,
  Toolbar,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const SelectedMenuItem = styled(MenuItem)(({ theme }) => ({
      position: 'relative',
      zIndex: 1,
      '&.selected::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '0 10px 10px 0px',
        zIndex: -1,
      },
    }));

const links = [
  { label: 'DASHBOARD', link: "dashboard", index: 0 },
  { label: 'PERSON', link: "person", index: 1 },
  { label: 'CHANGE LOG', link: "changelog", index: 2 }
];

const ApplicationNavbar = ({ mobileOpen, handleDrawerToggle, handleMenuItemClick, selectedItem, drawerWidth }) => {

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      {links.map((link) => (
        <SelectedMenuItem key={link.index} className={selectedItem === link.label ? 'selected' : ''}
          onClick={() => handleMenuItemClick(link.link)}>
          {link.label}
        </SelectedMenuItem>
      ))}
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default ApplicationNavbar;