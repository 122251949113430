import React from 'react';
import {
  Card,
  CardContent,
} from '@mui/material';
import GenogramDemo from '../../components/genogram';
    
function PersonalGenogram({ isSmallScreen}) {
        
      return (<Card
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              height: isSmallScreen ? '90%' :'auto', // Hier setzen wir die Höhe auf automatisch
              overflowY: isSmallScreen ? 'visible' : 'auto',
            }}
          >
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <GenogramDemo />
        </CardContent>
          </Card>
  );
}

export default PersonalGenogram;