import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import '../index.css'; // Stelle sicher, dass du die CSS-Datei erstellst
import { api } from '../hooks/api';
import { useAuth } from './auth_context';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [errors, setErrors] = useState({});      
      const { isAuthenticated, login } = useAuth();
      
      const navigate = useNavigate();
  
      useEffect(() => {
            if (isAuthenticated) {
                  navigate('/content');
            }
      }, [isAuthenticated, navigate]);
      
      const validate = () => {
            const newErrors = {};
            if (!email) {
              newErrors.email = 'E-Mail-Adresse ist erforderlich';
            } else if (!/\S+@\S+\.\S+/.test(email)) {
              newErrors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
            }
            if (!password) {
              newErrors.password = 'Passwort ist erforderlich';
            } else if (password.length < 0) {
              newErrors.password = 'Das Passwort muss mindestens 8 Zeichen lang sein';
            }
            return newErrors;
      };
        
      const handleSubmit = async (e) => {
            e.preventDefault();
            const validationErrors = validate();
            if (Object.keys(validationErrors).length > 0) {
              setErrors(validationErrors);
            } else {
                  if (await api.user_login(email, password) === false)
                  {
                        const newErrors = {};
                        newErrors.alert ='E-Mail Adresse oder Passwort ungültig'
                        setErrors(newErrors);
                        return;
                  }
                  login();
            }
      };
      
      return (
            <div className="container mt-5">
                  <div className="row justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                              <Box className="w-100"
                                    component="form"
                                    onSubmit={handleSubmit} 
                                    noValidate 
                                    sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                    <Typography component="h1" variant="h5">Anmelden</Typography>
                                    <TextField margin="normal" required fullWidth
                                          id="email"
                                          label="E-Mail-Adresse"
                                          name="email"
                                          autoComplete="email"
                                          autoFocus
                                          value={email}
                                          onChange={(e) => setEmail(e.target.value)}
                                          error={!!errors.email}
                                          helperText={errors.email}
                                    />
                                    <TextField margin="normal" required fullWidth
                                          id="password"
                                          label="Passwort"
                                          name="password"
                                          type="password"
                                          autoComplete="current-password"
                                          value={password}
                                          onChange={(e) => setPassword(e.target.value)}
                                          error={!!errors.password}
                                          helperText={errors.password}
                                    />
            
                                    {Object.keys(errors).length > 0 && (
                                          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                                                {errors.alert}
                                          </Alert>
                                    )}

                                    <Button type="submit" fullWidth variant="contained"
                                          sx={{ mt: 3, mb: 2 }}>Anmelden</Button>
                              </Box>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                              <img src="start.webp" alt="Playing Children" className="img-fluid"/>
                        </div>
                  </div>
            </div>
);
}

export default LoginForm;