import { Address, Avatar, Person, PersonMin } from "../model/Person";
import { Result } from "../model/result";
import { IAddress, IAvatar, IPerson, IPersonMin, SearchInfo } from "../model/types";

const API: string = "/api";
const PERSON : string = API + "/person";
const USER = API + "/user"

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
async function fetch_request(address : string) : Promise<Result<any>>
{
      try {
            console.log("Request: ", address);
            
            const response = await fetch(address);
            if (response.ok)
            {
                  const data = await response.json();
                  console.log("Request: ", address, " Done: ", data);
                  return Result.ok(data);      
            }
            else
            {
                  console.error("Invalid response ", address);
            }
      }
      catch (error) {
            console.error("Error request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
async function fetch_post_request(address : string, payload : any) : Promise<Result<any>>
{
      try {
            console.log("Post-Request: ", address, "payload: ", payload);

            const json = JSON.stringify(payload);
            const response = await fetch(address, {
                  method: 'POST',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: json,
                  credentials: 'include'
            });

            if (response.ok)
            {
                  const data = await response.json();

                  console.log("Post-Request: ", address, "payload: ", payload, " Done: ", data);
                  return Result.ok(data);      
            }
            else
            {
                  console.error("Invalid response : " , address , " " , response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
async function fetch_put_request(address : string, payload : any): Promise<Result<any>> {
      try {
            console.log("Put-Request: ", address)

            console.log(payload);
            const json = JSON.stringify(payload);
            const response = await fetch(address, {
                  method: 'POST',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: json,
                  credentials: 'include'
            });

            if (response.ok) {
                  const data = await response.json();
                  console.log("Put-Request: ", address, " Done: ", data);
                  return Result.ok(data);
            }
            else {
                  console.error("Invalid response : ", address, " ", response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
async function upload_request(address : string, payload : any) : Promise<Result<any>>
{
      try {
            console.log("Upload-Request: ", address, " payload: ", payload);
            //console.log(payload);

            const formData = new FormData();
            const blob = new Blob([payload], { type: 'application/octet-stream' });

            formData.append("file", blob, "1");

            const response = await fetch(address, {
                  method: 'POST',
                  
                  body: formData,
                  credentials: 'include'
            });

            if (response.ok)
            {
                  const data = await response.json();
                  console.log("Upload-Request: ", address, " Done");
                  return Result.ok(data);      
            }
            else
            {
                  console.error("Invalid response : '" + address + "' " + response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " " ,error);
      }

      return Result.failed();
}

export const api = {

      /**
       * The function `find_persons` asynchronously fetches and returns a
       * list of persons based on a search query.
       * 
       * @param search Enthält die Suchanfrage
       * @returns The `find_persons` function is returning the result
       * of the API call made to search for persons based on the
       * provided search query.
       */
      find_persons: async (search: SearchInfo): Promise<Result<IPersonMin[]>> => {
            const result = await fetch_post_request(PERSON + '/find', search);
            if (result.is_failed()) {
                  return Result.failed();
            }

            const persons: IPersonMin[] = result.data
                  .map((item: any) => PersonMin.from(item).to_object());
            
            return Result.ok(persons);
      },

      /* The `search` function in the `api` object is a helper function that allows you to perform a search
      query on the `PERSONS` API endpoint. It takes two parameters: `api` and `search`. The `api`
      parameter is a string that represents any additional API path you want to include in the search
      query, and the `search` parameter is the actual search query. */
      search: async (api: string, search: string) => {
            return await fetch_request(PERSON + api + search);
      },
      
      /**
       * The function `find_persons` asynchronously fetches and returns a
       * list of persons based on a search query.
       * @param id The Person id
       * @returns The `find_persons` gibt die gefundene Person zurück
       */
      get_person_by_id: async (id: number): Promise<Result<IPerson>> => {
            const result = await fetch_request(PERSON + '/' + id);
            if (result.is_failed()) {
                  return Result.failed();
            }

            return Result.ok(Person.from(result.data).to_object());
      },

      get_addresses_by_person_id: async (id: number): Promise<Result<IAddress[]>> => {
            const result = await fetch_request(PERSON + '/' + id + '/addresses');
            if (result.is_failed()) {
                  return Result.failed();
            }

            const addresses : IAddress[] = result.data.map((item: IAddress) => {
                  const address = Address.from(item).to_object();
                  return address;
            });

            return Result.ok(addresses);
      },

      /**
       * The function `find_persons` asynchronously fetches and returns a
       * list of persons based on a search query.
       * @param id The Person id
       * @returns The `find_persons` gibt die gefundene Person zurück
       */
      get_avatar_image_by_person_id: async (id: number): Promise<Result<IAvatar>> => {
            const result = await fetch_request(PERSON + '/' + id + "/avatar");
            if (result.is_failed()) {
                  return Result.failed();
            }
            return Result.ok(Avatar.from(result.data).to_object());
      },

      /**
       * The function `find_persons` asynchronously fetches and returns a
       * list of persons based on a search query.
       * @param id The Person id
       * @returns The `find_persons` gibt die gefundene Person zurück
       */
      update_person: async (person: IPerson): Promise<Result<IPerson>> => {
            const result = await fetch_put_request(PERSON + '/' + person.id, person);
            if (result.is_failed()) {
                  return Result.failed();
            }
            return Result.ok(Person.from(result.data).to_object());
      },
      // /{id}/avatar
      avatar_to_person: async (person_id: number, content: any) => {
            const value = await upload_request(PERSON + '/' + person_id + '/avatar', content);
            return value;
      },

      upload_doc_to_person: async (person_id: number, content: any) => {
            const value = await upload_request(PERSON + '/' + person_id + '/doc/upload', content);
            return value;
      },

      user_login: async (email: string, password: string) : Promise<boolean> => {

            console.log("user login: ", email);

            const loginDetails = {
                  email: email,
                  password: password
            };
      
            try {
                  const login_status = await fetch_post_request(USER + '/login', loginDetails);
                  console.log("user login: ", email, " Done");
                  return login_status.is_ok();
            } catch (error) {
                  console.error("Error user_login", error);
            }
            
            return false;
      },

      user_logout: async () => {
            console.log("user logout");

            try {
                  await fetch_post_request(USER + '/logout', '');
            } catch (error) {
                  console.error("Error user_logout", error);
            }
      },
      verify_user_authentication: async () => {

            console.log("verify_user_authentication");

            try {
                  const login_status = await fetch_post_request(USER + '/auth', "")
                  console.log("verify_user_authentication Done");
                  return login_status.is_ok;
            } catch (error) {
                  console.error("Error during login: ", error);
            }
      
            return false;
      }
}

