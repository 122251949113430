import React from 'react';
import ReactDOM from 'react-dom';
import { useDialog } from './dialog_context';
import {
      Modal
      
 } from '@mui/material';
const DialogPortal = () => {
      const { dialog, hideDialog } = useDialog();

      if (!dialog) return null;

      return ReactDOM.createPortal(
                  <Modal show="false" onHide={hideDialog} centered>
                        {React.cloneElement(dialog, { onClose: hideDialog })}
                  </Modal>,
                  document.body
            );
};

export default DialogPortal;
