import React, {useEffect, useRef, useState }from 'react';
import {
      TextField,
      Box,
      List,
      Button,
      Modal
} from '@mui/material';
import ReactDOM from 'react-dom';

interface ModalAddressSearchProps {
      open: boolean;
      handleClose: () => void;
      handleSelect: (id: number) => void;
}
export const ModalAddressSearch: React.FC<ModalAddressSearchProps> = ({open, handleClose, handleSelect}) => {
      const textFieldRef = useRef<HTMLInputElement>(null);
      const [query, setQuery] = useState<string>('');

      const handlerSearchTextChanged = (e: string) => {
            setQuery(e);
      }

      useEffect(() => {
            setTimeout(() => {
                  if (textFieldRef.current) {
                        textFieldRef.current.focus();
                  }
            }, 0);
      });

      useEffect(() => { }, [query]);
      
      return ReactDOM.createPortal(
            <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
            >
                  <Box sx={{ width: 'auto', height: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 1, overflow: 'hidden' }}>
                        <Box sx={{ width: 600, overflowY: 'auto', padding: 2 }}>
                              <h2 id="modal-title">Nach Adresse suchen</h2>
                              <TextField
                                    inputRef={textFieldRef}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    label="Suche nach"
                                    value={query}
                                    onChange={(e) => {
                                          handlerSearchTextChanged(e.target.value);
                                    }}
                              />
                              <Box sx={{
                                    height: 300,
                                    overflowY: 'auto',
                                    mt: 2,
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                              }}>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                          {
                                                // persons.map((person) => (
                                                //       <PersonListItem
                                                //             person={person}
                                                //             key={person.id}
                                                //             handleSelectPerson={handleSelect}
                                                //       />
                                                // ))
                                          }
                                    </List>
                              </Box>
                        </Box>
                        <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                              Close
                        </Button>
                  </Box>
            </Modal>
            , document.body);
};

export default ModalAddressSearch;
