import React, { Component, useEffect } from 'react';
import { useDialog } from './dialog_context';
import { Modal, Button } from '@mui/material';
function LoginFaildDialog({ show }) {
      const { hideDialog } = useDialog();

      return <div>
            <Modal.Header closeButton>
                  <Modal.Title>Login:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  <h1>Falsch!</h1>
                  <Button onClick={hideDialog}>OK</Button>
            </Modal.Body>
      </div>;
}

export default LoginFaildDialog;