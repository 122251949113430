import { PersonMin } from "../../model/Person";
import ImageIcon from '@mui/icons-material/Image';
import {
      ListItemButton,
      ListItemAvatar,
      Avatar,
      Typography,
} from '@mui/material';

interface ListItemProps {
      person: PersonMin;
      handleSelectPerson: (id: number) => void;
}
    
const PersonListItem: React.FC<ListItemProps> = ({ person, handleSelectPerson }) => {
      return (
            <ListItemButton onClick={() => handleSelectPerson(person.id)} key={person.id.toString()}>
                  <ListItemAvatar>
                        <Avatar>
                              <ImageIcon />
                        </Avatar>
                  </ListItemAvatar>
                  <Typography sx={{ fontSize: 14, marginRight: 1 }} color="text.secondary" gutterBottom>
                        {person.names}
                  </Typography>
            </ListItemButton>
      );
};
export default PersonListItem;