import React, { useEffect } from 'react';
import { fetch_person_by_id_async } from './personSlice'
import { useDispatch, useSelector } from 'react-redux';
import { person_update, person_name_update } from './personSlice';
import {
  TextField,
  MenuItem,
  IconButton,
} from '@mui/material';
import PlusOne from '@mui/icons-material/AddCircle';
const genders = [
      {
        value: 'mr',
        label: 'Herr',
      },
      {
        value: 'ms',
        label: 'Frau',
      },
      {
        value: 'other',
        label: 'Andere',
      },
    ];
    
function PersonalFormula({ isSmallScreen, person }) {
  const dispatch = useDispatch()
  //let person = useSelector(state => state.person.person);

  const handlerInputChange = (e) => {
    console.log("handleInputChange");
    console.log(e);
    const { name, value } = e.target;
    dispatch(person_update({ [name]: value }));
  };

  const handlerNameInputChange = (e, item, index) => {
    console.log("handleNameInputChange");
    console.log(e);
    const { label, value } = e.target;
    dispatch(person_name_update({ index, value }));
  };
  
  const handlerSetDateOfBirth = (e) => {
    const { name, value } = e.target;
    dispatch(person_update({ [name]: value }));
  }

  return (
    <>
      <TextField
        select
        label="Anrede"
        value={person && person.genders}
        onChange={handlerInputChange}
        variant="outlined"
      >
        {genders.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Titel"
        name="title"
        value={person.title || ''}
        onChange={handlerInputChange}
        variant="outlined"
      />

      {
        person.names && person.names.map((item, index) => (
          <TextField
            key={item.label}
            label={item.label}
            name={item.label}
            value={item.name || ''}
            onChange={(e) => handlerNameInputChange(e, item, index)}
            variant="outlined"
          />
        ))
      }

      <IconButton aria-label="Neu" size="small">
        <PlusOne />
      </IconButton>
      
      <TextField
        label="Geburtsdatum"
        type="date"
        name="date_of_birth"
        value={person.date_of_birth || ''}
        onChange={handlerSetDateOfBirth}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {/* </Container> */}
      {/* </Paper> */}
    </>
  );
}

export default PersonalFormula;